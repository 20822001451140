import React from "react"

import Layout from "../../components/Layout"
import DockingtonReports from "../../views/DockingtonReports"

const DockingtonReportsPage = ({ location, pageContext: { locale } }) => (
    <Layout location={location} locale={locale}>
        <DockingtonReports location={location} />
    </Layout>
);

export default DockingtonReportsPage;
