import * as React from 'react';

import OptInForm, { NameField, EmailField, SubmitButton, ErrorLabel } from '../../components/common/OptInForm';
import Row from '../../components/common/Row';
import Col from '../../components/common/Col';

import processText from '../processText';
import { cn } from '../../util';
import withTranslate from '../../components/translate';
import OptInThankYouModal from '../../components/common/OptInThankYouModal';

const reports = require("../../assets/images/dockington-reports-bar-graph.jpg");
const styles = require("./DrSignUpForm.module.css");

export interface DrSignUpFormProps {
    content: {
        title: any,
        text: any,
        disclaimer: any,
        subscribe: any,
        subscribing: any,
    },
    signupFrom?: string,
    onSubscribe?: () => void,
    translate: (key: string) => string,
}
 
const DrSignUpForm: React.FC<DrSignUpFormProps> = ({ content, signupFrom, translate, onSubscribe }) => {
    let [ showThankYou, setShowThankYou ] = React.useState(false);
    return (
        <OptInForm
            signUpUrl="https://europe-west2-dockington.cloudfunctions.net/subscribedr"
            onSubscribe={() => {
                if (onSubscribe) {
                    onSubscribe();
                } else {
                    setShowThankYou(true);
                }
            }}
            signupFrom={signupFrom}
            translate={translate}
        >
            <div className={styles.body}>
                <h2>
                    <div className={styles.title}>{processText(content.title)}</div>
                </h2>
                <div className={styles.text}>
                    {processText(content.text)}
                </div>
                <Row className="mx-0">
                    <Col xs12 m6 l4 className="px-0 px-md-05">
                        <NameField />
                    </Col>
                    <Col className="px-0 px-md-05">
                        <EmailField />
                    </Col>
                    <Col xs12 className="col-lg-auto px-0 px-md-05" align="top" justify="right">
                        <Row>
                            <Col xs12 className="col-sm-auto" orderS={2}>
                                <SubmitButton
                                    className={styles.submit}
                                    actionText={content.subscribe}
                                    loadingText={content.subscribing}
                                />
                            </Col>
                            <Col xs12 s orderS={1} className="d-lg-none">
                                <ErrorLabel />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs12 hideM>
                        <ErrorLabel />
                    </Col>
                </Row>
                <div className={styles.disclaimer}>
                    {processText(content.disclaimer)}
                </div>
            </div>
            <OptInThankYouModal
                modalClassName={styles.modal}
                isOpen={showThankYou}
                onClose={() => {
                    setShowThankYou(false);
                }}
                translate={translate}
            />
        </OptInForm>
    );
}
 
export default withTranslate(DrSignUpForm);
