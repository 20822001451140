import * as React from 'react';

import withTranslate from '../../components/translate';
import OptInThankYouModal from '../../components/common/OptInThankYouModal';
import DrSignUpForm from './DrSignUpForm';
import Modal from '../../components/common/Modal';
import Button from '../../components/common/Button';

import processText from '../processText';

const styles = require("./DrOptInButton.module.css");

export interface DrOptInButtonProps {
    translate: (key: string) => string,
}
 
const DrOptInButton: React.FC<DrOptInButtonProps> = ({ translate }) => {
    let [ showPopup, setShowPopup ] = React.useState(false);
    let [ showThankYou, setShowThankYou ] = React.useState(false);
    return (
        <div>
            <Button className={styles.button} onClick={() => {
                if ((window as any).gtag) {
                    (window as any).gtag('event', "dr-signup-btn", {
                        'event_category': 'dr-signup-btn',
                        'event_label': "Click DR signup button"
                    });
                }
                setShowPopup(true);
            }}>
                {processText(translate("reports.signup.button"))}
            </Button>
            <Modal
                className={styles.modal}
                isOpen={showPopup}
                contentLabel="Subscribe"
                onClose={() => {
                    if ((window as any).gtag) {
                        (window as any).gtag('event', "dr-signup-close", {
                            'event_category' : 'dr-signup-form',
                            'event_label' : "Close DR signup popup"
                        });
                    }
                    setShowPopup(false);
                }}
                closeBtnColor="#bbb"
            >
                <DrSignUpForm
                    content={translate("reports.signup.form")}
                    signupFrom="optin-button"
                    onSubscribe={() => {
                        setShowPopup(false);
                        setShowThankYou(true);
                    }}
                />
            </Modal>
            <OptInThankYouModal
                modalClassName={styles.modal}
                isOpen={showThankYou}
                onClose={() => {
                    setShowThankYou(false);
                }}
                translate={translate}
            />
        </div>
    );
}
 
export default withTranslate(DrOptInButton);
