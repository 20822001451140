import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SEO from '../../components/SEO';
import ScrollToTopOnMount from '../../components/common/ScrollToTopOnMount';
import Row from '../../components/common/Row';
import Col from '../../components/common/Col';
import List from '../../components/common/List';
import Jumbotron from '../../components/common/Jumbotron';
import ProductHighlight from '../../components/common/ProductHighlight';
import NavBar from '../../components/common/NavBar';
import { ReviewBody } from '../../components/common/Review';
import JumbotronFluid from '../../components/common/JumbotronFluid';
import FAQ from '../MainPage/FAQ';

import withTranslate from '../../components/translate';
import processText from '../processText';
import { parseQueryParams } from "../../util";
import pieChart from "../../assets/images/dockington-reports-piechart.png";
import areaChart from "../../assets/images/dockington-reports-areachart.png";
import trendChart from "../../assets/images/dockington-reports-trends.png";
import aurora from "../../assets/images/aurora.png";
import workFromAnywhere from "../../assets/images/work-from-anywhere.jpg";
import happySecretary from "../../assets/images/happy-secretary.jpg";
import agreement from "../../assets/images/agreement.jpg";

import "./DockingtonReports.css";
import DrSignUpForm from './DrSignUpForm';
import DrOptInButton from './DrOptInButton';

const PREORDER_DISCOUNT_CODE = "Z8bubjP8JUrd2r0Ej3K2";

const DockingtonReports = withTranslate(({ location, localePath, locale, translate }) => {
    let hasDiscount = false;
    let showThankYou = false;
    if (location.search) {
        let query = parseQueryParams(location.search);
        if (query.disc === PREORDER_DISCOUNT_CODE) {
            hasDiscount = true;
        }
        if (query.thankyou) {
            showThankYou = query.thankyou;
        }
    }
    const colors = [
        "#33AADD",
        "#00D8D0",
        "#2CE671",
        // "#87F600",
        // "#D3FC00",
        // "#FFFD00",
        "#FFD200",
        // "#FFA900",
        "#FF7100",
        "#FE3136",
        "#EC2D9D",
        "#1C37E0",
    ];
    const objectionImages = [
        workFromAnywhere,
        agreement,
        happySecretary,
    ];

    return (
        <div className="dr-page">
            <SEO
                title={translate("reports.head.title")}
                description={translate("reports.head.description")}
                keywords={translate("reports.head.keywords")}
                path="/dockington-reports"
                meta={[
                    {
                        property: "og:image",
                        content: `https://www.dockington.com/images/dockington-reports-bar-graph.jpg`,
                    },
                    {
                        property: "og:url",
                        content: `https://www.dockington.com${localePath}/dockington-reports`,
                    }
                ]}
            />
            <ScrollToTopOnMount />
            <NavBar white translate={translate} />
            <div>
                <div className="intro-with-bg">
                    <div className="intro-bg">
                        <div className="intro-bg-color" />
                        <div className="intro-bg-stripe" />
                    </div>
                    <div className="container">
                        <header className="container pt-3 pb-2 pt-sm-4 pb-sm-5" style={{ position: "relative" }}>
                            <div className="intro tagline">
                                <h1>
                                    {processText(translate("reports.title"))}
                                </h1>
                            </div>
                        </header>
                    </div>
                </div>
                <Jumbotron color="#fff">
                    <Row justify="center" className="mx-0">
                        <div className="" style={{ maxWidth: 800}}>
                            <div className="intro">
                                <h2 className="mb-2">
                                    {processText(translate("reports.understanding.title"))}
                                </h2>
                                <div className="h2-sub mb-2">
                                    {translate("reports.understanding.subtitle")}
                                </div>
                                <DrOptInButton />
                                {translate("reports.understanding.text").map((part, i) => (
                                    <div className="mb-1 text-left" key={i}>{processText(part)}</div>
                                ))}
                            </div>
                        </div>
                    </Row>
                </Jumbotron>
                <JumbotronFluid color="#fafafa">
                    {/* <h2>
                        {processText(translate("reports.solution.title"))}
                    </h2> */}
                    <ProductHighlight
                        steps={[
                            {
                                title: translate("reports.solution.setup.title"),
                                text: translate("reports.solution.setup.text"),
                                component: () => (
                                    <img
                                        style={{ borderRadius: "4px" }}
                                        className="product-img img-shadow"
                                        src={trendChart}
                                        alt={translate("reports.solution.setup.imgAlt")}
                                    />
                                ),
                            },
                            {
                                title: translate("reports.solution.visualize.title"),
                                text: translate("reports.solution.visualize.text"),
                                component: () => (
                                    <img
                                        style={{ borderRadius: "4px" }}
                                        className="product-img img-shadow"
                                        src={areaChart}
                                        alt={translate("reports.solution.visualize.imgAlt")}
                                    />
                                ),
                            },
                            {
                                title: translate("reports.solution.know.title"),
                                text: translate("reports.solution.know.text"),
                                component: () => (
                                    <img
                                        style={{ borderRadius: "4px" }}
                                        className="product-img img-shadow"
                                        src={pieChart}
                                        alt={translate("reports.solution.know.imgAlt")}
                                    />
                                ),
                            },
                        ]}
                    />
                </JumbotronFluid>
                {/* <Jumbotron color="#fff">
                    <h2>
                        {processText(translate("reports.solution.includesTitle"))}
                    </h2>
                    <Row justify="center" className="mt-2 px-md-2 text-left">
                        <List
                            items={translate("reports.solution.includes").map((point, idx) => ({
                                marker: <FontAwesomeIcon icon="check-circle" color={colors[idx % colors.length]} />,
                                text: processText(point),
                            }))}
                        />
                    </Row>
                </Jumbotron> */}
                {/* <JumbotronFluid color="#fff">
                    <Row justify="center" className="my-2 px-md-2">
                        <Col>
                            <AutoPlayInViewVideo>
                                <source src={articleAutofill} type="video/mp4" />
                                <source src={articleAutofillMobile} type="video/webm" />
                            </AutoPlayInViewVideo>
                        </Col>
                    </Row>
                </JumbotronFluid> */}
                {/* {translate("reports.trust.objections").map((objection, idx) => (
                    <div key={idx} className="objections pb-3" style={{ backgroundColor: "#fafafa" }}>
                        <Row justify={idx % 2 !== 0 ? "left" : "right"} className="objections-row mx-0">
                            <Col xs12 className="px-0 col-lg-auto img-container" orderL={idx % 2 !== 0 ? 1 : 2}>
                                <img src={objectionImages[idx % objectionImages.length]} alt={objection.imgAlt} />
                            </Col>
                            <Col justify={idx % 2 !== 0 ? "left" : "right"} align="center" xs12 l orderL={idx % 2 !== 0 ? 2 : 1} className="px-2 px-lg-0">
                                <div className={"px-2 py-2 text-container " + (idx % 2 !== 0 ? "left" : "right")}>
                                    <h2 className="mb-2">{processText(objection.title)}</h2>
                                    {objection.text.map((part, idx) => (
                                        <p key={idx}>{processText(part)}</p>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))} */}
                {/* <Jumbotron color="#fafafa">
                    <div className="intro mt-2">
                        <h2>
                            {translate("reports.trust.title")}
                        </h2>
                        <p className="h2-sub mb-2">{processText(translate("reports.trust.subtitle"))}</p>
                    </div>
                    <Row justify="center" className="mx-0 mb-2">
                        <div className="" style={{ maxWidth: 920 }}>
                            <ul className="points text-left">
                                {translate("reports.trust.points").map((item, i) => (
                                    <li key={i} className="item">
                                        <div className="bad">
                                            <FontAwesomeIcon icon="times" />
                                        </div>
                                        <div>
                                            <p className="emph">{item.reason}</p>
                                            <p>{item.comment}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Row>
                </Jumbotron> */}
                <Jumbotron color="#4c44fb">
                    <ReviewBody review={translate("reports.proof.aurora")} image={{ src: aurora, alt: "Aurora" }} />
                </Jumbotron>
                <Jumbotron color="#fafafa">
                    <Row justify="center">
                        <DrSignUpForm
                            content={translate("reports.signup.form")}
                            signupFrom="dr-signup-form"
                        />
                    </Row>
                </Jumbotron>
                <Jumbotron color="#fff">
                    <FAQ
                        className="text-left mb-5"
                        title={translate("main.faq.title")}
                        points={translate("reports.faq.points")}
                    />
                </Jumbotron>
            </div>
        </div>
    );
});
 
export default DockingtonReports;
