import React from "react";

import Link from './Link';
import Row from './Row';
import Col from './Col';

import "./Review.css";

export const ReviewBody = function ({ review, image, className }) {
    return (
        <Row className={"review" + (className ? ` ${className}` : "")} align="center">
            <Col xs12 justify="center" className="review-image-container">
                <img
                    className="review-image"
                    src={image.src}
                    alt={image.alt}
                />
            </Col>
            <Col xs12 m className="px-1 pl-sm-2">
                <h3 className="review-title">{review.title}</h3>
                {review.quote.map((line, i) => (
                    <p key={line} className="quote">
                        {i === 0 ? <span>&ldquo;</span> : null}{
                            Array.isArray(line) ? line.map((piece, i) => (typeof piece === "string" ? piece : <span key={"emph" + i} className="quote-emph">{piece.emph}</span>)) : line
                        }{i + 1 === review.quote.length ? <span>&rdquo;</span> : null}
                    </p>
                ))}
                <div className="quote-author">
                    <p className="quote-author-name">{review.author.name}</p>
                    <p className="quote-author-title">{review.author.title}</p>
                </div>
            </Col>
        </Row>
    );
}

const Review = function (props) {
    const { review } = props;
    return (
        <Link className="review-link" to={"/testimonials#" + review.id}>
            <ReviewBody {...props} />
        </Link>
    );
}

export default Review;
